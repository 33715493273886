<template>
	<div class="coupon-add">
		<el-card>
			<el-row :gutter="20" class="left">
				<el-col :span="24">
					<i class="el-icon-arrow-left" style="color: #30aaf1; cursor: pointer" @click="back">
			            <span>返回优惠券列表</span>
			        </i>
			         - 
		            <span v-if="!this.$route.query.id">新增优惠券</span>
		            <span v-else>编辑优惠券</span>
				</el-col>
			</el-row>
			<el-divider></el-divider>
			<el-form ref="yhq_form" :rules="yhq_rules" :model="yhq_info" label-width="100px" class="pl left">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item label="优惠券名称" prop="coupon_name">
							<el-input style="width:50%" v-model="yhq_info.coupon_name" placeholder="请输入优惠券名称" maxlength="20" show-word-limit></el-input>
						</el-form-item>
						<el-form-item label="优惠券备注">
							<el-input style="width:50%" v-model="yhq_info.sub_name" placeholder="请输入优惠券备注" maxlength="16" show-word-limit></el-input>
						</el-form-item>
			            <el-form-item label="封面图" prop="img_list">
			              <div style="display:flex">
			                <div class="previewUpload">
			                  <div v-show="yhq_info.coupon_banner" class="previewItem">
			                    <img :src="yhq_info.coupon_banner" alt="" @click="openUploadDialog(1,'cover')">
			                    <i class="el-icon-close delIcon" @click="delCoverItem(index)"></i>
			                  </div>
			                  <div class="previewItems" v-if="!yhq_info.coupon_banner" @click="openUploadDialog(1,'cover')"><i class="el-icon-plus previewadd"></i></div>
			                </div>
			                <el-tooltip placement="right" class="tooltips">
			                  <div slot="content">图片建议尺寸：200 * 200</div>
			                  <i class="el-icon-question" style="font-size: 18px"></i>
			                </el-tooltip>
			              </div>
			            </el-form-item>
						<el-form-item label="起止时间" prop="start_time">
							<el-date-picker style="width:30%" v-model="yhq_info.start_time" type="datetime" placeholder="选择开始日期时间" value-format="yyyy-MM-dd HH:mm:ss">
							</el-date-picker>
							<span> ~ </span>
							<el-date-picker style="width:30%" v-model="yhq_info.end_time" type="datetime" placeholder="选择结束日期时间" value-format="yyyy-MM-dd HH:mm:ss">
							</el-date-picker>
							<el-tooltip placement="right">
								<div slot="content">领取的卡券必须在该时间前使用</div>
								<i class="el-icon-question" style="font-size: 18px;margin-left:10px"></i>
							</el-tooltip>
						</el-form-item>

						<el-form-item label="优惠券类型" style="height:100%">
							<el-radio-group v-model="yhq_info.coupon_type">
								<el-radio v-for="(item,index) in init_data.coupon_type" :label="index">{{item}}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="优惠金额" prop="coupon_worth" v-if="yhq_info.coupon_type==1">
							<el-input style="width:30%" v-model="yhq_info.coupon_worth" placeholder="请输入优惠金额" min="0.01" show-word-limit type="number"></el-input>
							<span class="mr3">元</span>
						</el-form-item>
						<el-form-item label="优惠折扣" prop="coupon_worth" v-if="yhq_info.coupon_type==2">
							<el-input style="width:30%" v-model="yhq_info.coupon_worth" placeholder="请输入优惠折扣" min="0.1" max="9.9" show-word-limit type="number"></el-input>
							<span class="mr3">折</span>
						</el-form-item>
						<el-form-item label="礼品券额" prop="coupon_value" v-if="yhq_info.coupon_type==3">
							<el-input style="width:30%" v-model="yhq_info.coupon_value" placeholder="请输入礼品券券额" maxlength="16" show-word-limit></el-input>
						</el-form-item>
						<el-form-item label="使用条件" style="height:100%">
							<span>消费满</span>
							<el-input class="mr3" style="width:150px" v-model="yhq_info.coupon_man" placeholder="请输入消费金额"></el-input>
							<span class="mr3">元可用</span>
						</el-form-item>
						<el-form-item label="优惠券数量" prop="coupon_count">
							<el-input style="width:30%" v-model.number="yhq_info.coupon_count" placeholder="请输入优惠券数量" type="number" v-if="!yhq_info.is_ready"></el-input>
							<el-input style="width:30%" v-model.number="yhq_info.coupon_count" placeholder="请输入优惠券数量" type="number" :disabled="true" v-else></el-input>

							<el-input style="width:30%" v-model.number="plus_coupon_count" placeholder="请输入新增优惠券数量" type="number" class="mr3" v-if="showPlusCount"></el-input>
							<el-button type="primary" plain class="mr3" @click="surePlus" v-if="showPlusCount">确定</el-button>
							<el-button type="primary" plain icon="el-icon-plus" class="mr3" @click="showPlusCount=!showPlusCount" v-if="!showPlusCount"></el-button>
							<el-button type="danger" plain icon="el-icon-minus" class="mr3" @click="showPlusCount=!showPlusCount" v-if="showPlusCount"></el-button>

							<span class="mr3"></span>
						</el-form-item>
						<el-form-item label="排序值" prop="coupon_sort">
							<el-input style="width:30%" v-model.number="yhq_info.coupon_sort" placeholder="请输入排序值"></el-input>
							<el-tooltip placement="right">
								<div slot="content">值越大, 排序越靠前</div>
								<i class="el-icon-question" style="font-size: 18px;margin-left:10px"></i>
							</el-tooltip>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<el-col :span="13">
						<el-form-item label="使用规则" prop="coupon_desc">
							<el-input type="textarea" v-model="yhq_info.coupon_desc" maxlength="1000" show-word-limit :rows="10" placeholder="请输入使用规则">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<el-col :span="12">
						<el-form-item label="用兑换码领取">
							<el-checkbox v-model="yhq_info.is_redeem">需要</el-checkbox>
						</el-form-item>
						<el-form-item label="领取身份">
							<el-radio-group v-model="yhq_info.coupon_lqsf">
								<el-radio v-for="(item,index) in init_data.coupon_lqsf" :label="index">{{item}}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="领取次数" style="height:100%">
							<el-radio-group v-model="yhq_info.send_rate">
								<el-radio label="1">一次</el-radio>
								<el-radio label="2">一天一次</el-radio>
								<el-radio label="3">不限制</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="限用服务" prop="">
							<el-button type="primary" icon="el-icon-plus" @click="xyfwVisible = true">选择服务</el-button>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item prop="limitService">
					<el-table :data="yhq_info.limitService" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
						<el-table-column type="index" label="ID" width="200px"></el-table-column>
						<el-table-column prop="service_name" label="服务名称"></el-table-column>
						<el-table-column prop="service_price" label="服务价格"></el-table-column>
						<el-table-column label="操作" width="100px">
							<template slot-scope="scope">
								<div>
									<el-button type="danger" plain size="mini" @click="remove(scope.$index)">删除</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="subYHQ">提交</el-button>
					<el-button type="default" @click="back">返回</el-button>
				</el-form-item>
			</el-form>
			<!-- 对话框 -->
			<el-dialog title="限用服务" center width="50%" :visible.sync="xyfwVisible">
				<el-row :gutter="10">
					<el-col :span="24">
						<span>输入查询</span>
						<el-input class="mr10" style="width:300px" v-model="searchFW" placeholder="请输入服务名称" clearable></el-input>
						<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					</el-col>
				</el-row>
				<el-table ref="xyfwTable" style="margin:15px 0" :data="xyfwdata" :header-cell-style="{ background: '#eef1f6', color: '#606266' }" @selection-change="handleSelectionChange">
					<el-table-column type="selection"></el-table-column>
					<el-table-column prop="service_name" label="服务名称"></el-table-column>
					<el-table-column prop="service_price" label="服务价格"></el-table-column>
				</el-table>
				<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
				<div style="text-align:center">
					<el-button type="default" @click="close">取消</el-button>
					<el-button type="primary" @click="sub">确定</el-button>
				</div>
			</el-dialog>

			<Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>

		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	import Upload from '@/components/Upload/Upload.vue'
	export default {
		components: {
			Pagination,Upload
		},
		data() {
			const coupon_worth = (rule, value, callback) => { //自定义规则校验
				if(this.yhq_info.coupon_type == 1 && !this.yhq_info.coupon_worth) {
					callback(new Error('请输入优惠金额'))
				}
				if(this.yhq_info.coupon_type == 1 && this.yhq_info.coupon_worth<0.01) {
					callback(new Error('请输入正确的优惠金额'))
				}
				if(this.yhq_info.coupon_type == 2 && !this.yhq_info.coupon_worth) {
					callback(new Error('请输入优惠折扣'))
				}
				if(this.yhq_info.coupon_type == 2 && (this.yhq_info.coupon_worth<0.1||this.yhq_info.coupon_worth>9.9)) {
					callback(new Error('请输入正确的优惠折扣'))
				}
				callback()
			}
			const coupon_value = (rule, value, callback) => { //礼品券需要填写礼品名
				if(this.yhq_info.coupon_type == 3 && !this.yhq_info.coupon_value) {
					callback(new Error('请输入礼品券券额'))
				}
				callback()
			}
			const start_time = (rule, value, callback) => { //礼品券需要填写礼品名
				if(!this.yhq_info.start_time) {
					callback(new Error('请输入开始时间'))
				}
				if(!this.yhq_info.end_time) {
					callback(new Error('请输入结束时间'))
				}
				callback()
			}
			const coupon_count = (rule, value, callback) => {
				if(typeof this.yhq_info.coupon_count < 1) {
					callback(new Error('请输入卡券库存'))
				}
				callback()
			}

			const limitService = (rule, value, callback) => {
				if(this.yhq_info.limitService.length == 0) callback(new Error('请选择限用服务'))
				callback()
			}
			return {
				showPlusCount: false,
				plus_coupon_count: '',

				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 5,
				},
				total: 0,
				yhq_info: {
					coupon_name: '',
					sub_name: '',
					coupon_banner: '',
					coupon_type: '1',
					coupon_value: '',
					coupon_worth: '',
					coupon_desc: '',
					coupon_count: '',
					start_time: '',
					end_time: '',
					is_ready: 0,
					coupon_man: '',
					coupon_lqsf: '1',
					send_rate: '1',
					using_type: 0,
					using_day: '',
					using_time: '',
					is_redeem: 0,
					coupon_sort: 99,
					limitService: [],
				},
				init_data: {
					coupon_sytj: [],
					coupon_sysj: [],
					coupon_type: []
				},
				yhq_rules: {
					coupon_name: [{
						required: true,
						message: '请输入优惠券名称',
						trigger: 'blur'
					}],
					start_time: [{
						required: true,
						validator: start_time,
						trigger: 'blur'
					}],
					coupon_worth: [{
						validator: coupon_worth,
						trigger: 'blur'
					}],
					coupon_value: [{
						validator: coupon_value,
						trigger: 'blur'
					}],
					coupon_desc: [{
						message: '请输入使用规则',
						trigger: 'blur'
					}],
					coupon_count: [{
						required: true,
						validator: coupon_count,
						trigger: 'blur'
					}, ],
					coupon_sort: [{
							required: true,
							message: '请输入排序值',
							trigger: 'blur'
						},
						{
							type: 'number',
							message: '请输入数字'
						}
					],

					limitService: [{
						validator: limitService,
						trigger: 'blur'
					}, ],

				},
				serviceData: [],
				xyfwdata: [],
				xyfwVisible: false,
				searchFW: '',

				chooseImgCount:0,
		      	pictureType:'',
		      	uploadVisible:false,
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getInitData();
				this.getService();
				if(this.$route.query.id){
					this.getCouponDetail();
				}
			}
		},
		mounted() {},
		methods: {
			getInitData() {
				var url = 'coupon/init_data';
				let params = {};
				this.fd_post(url, params).then((res) => {
					that.init_data = res;
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getService() {
				var url = 'service/service_list';
				let params = {
					service_name: this.searchFW,
					is_on_sale: 1,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.xyfwdata = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getCouponDetail(){
				var url = 'coupon/coupon_detail';
				let params = {
					coupon_id: this.$route.query.id
				};
				this.fd_post(url, params).then((res) => {
					// console.log(res)
					if(res.status) {
						that.yhq_info = res.detail;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			back() {
				this.serviceData = []
				this.$router.go(-1)
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getService();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getService();
			},
			search() {
				this.getService();
			},
			remove(index) {
				this.yhq_info.limitService.splice(index, 1)
			},
			handleSelectionChange(val) {
				this.serviceData = val;
			},
			close() {
				this.xyfwVisible = false;
				this.serviceData = [];
			},
			sub() {
				this.xyfwVisible = false
				let newarr = [...this.yhq_info.limitService, ...this.serviceData]
				let map = new Map()
				this.yhq_info.limitService = newarr.filter(item => !map.has(item.service_id) && map.set(item.service_id, 1))
				this.serviceData = [];
				this.$refs.xyfwTable.clearSelection()
			},
			subYHQ() {
				this.$refs.yhq_form.validate(valid => {
					if(!valid) return;
					if(timer != null){clearTimeout(timer);}
    				timer = setTimeout(() => {
						that.yhq_info.service_ids = JSON.stringify(that.yhq_info.limitService);
						var url = 'coupon/addOrUpdate';
				        let params = that.yhq_info;
				        params.is_redeem = that.yhq_info.is_redeem?1:0;
				        console.log(params)
				        that.fd_post(url, params).then((res) => {
			        		if(res.status){
			        			that.$refs.yhq_form.resetFields();
			        			that.$message.success("操作成功");
			        			that.$router.push({path: '/spread_coupon'});
			        		}else{
			        			that.$message.error(res.msg);
			        		}
				        }).catch((err) => {
				            that.$message.error('网络错误');
				        });
				    },1000);
				})
			},

			openUploadDialog(count,pictureType){
		      this.pictureType = pictureType
		      this.chooseImgCount = count
		      this.uploadVisible=  true
		    },
		    closeDelDialog(){
		      this.uploadVisible = false
		    },
		    receiveRes(chooseList,pictureType){
		    	console.log(chooseList)
		    	console.log(pictureType)
		      	if(pictureType==='cover'){
		        	this.yhq_info.coupon_banner = chooseList[0].img_path;
		      	}
		      	this.uploadVisible = false;
		    },
		    surePlus() {
		    	if (this.yhq_info.coupon_id) {
		    		if (this.plus_coupon_count>0) {
		    			this.showPlusCount = false;
			    		var url = 'coupon/ready_coupon';
						let params = {
							coupon_id: this.yhq_info.coupon_id,
							coupon_count: this.plus_coupon_count,
						};
						this.fd_post(url, params).then((res) => {
							if(res.status) {
								this.yhq_info.coupon_count = this.yhq_info.coupon_count+this.plus_coupon_count;
								this.plus_coupon_count = '';
								this.$message.success("追加成功");
							} else {
								this.$message.error(res.msg);
							}
						}).catch((err) => {
							this.$message.error('网络错误');
						});
			    	} else {
			    		this.$message.error('请输入数量');
			    	}
		    	}
		    },
		},
	}
</script>

<style lang="scss" scoped>
	@import './Coupon_add.scss';
</style>